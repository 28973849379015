import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import styles from '../sass/components/instructions.module.scss';
import Container from './container';

const Instructions = ({ instructions }) => (
  <Container>
    <ul className={styles.instructions}>
      {instructions.map((item, key) => (
        <li key={key} className={styles.instructions__item}>
          <figure className={styles.instructions__image}>
            <Img
              fixed={item.image.fixed}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              imgStyle={{ objectFit: '' }}
              alt={item.title}
            />
          </figure>
          <strong className={styles.instructions__title}>{item.title}</strong>
          <p className={styles.instructions__text}>{item.text}</p>
        </li>
      ))}
    </ul>
  </Container>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsIntroduction {
          instructions {
            title
            text
            image {
              url
              fixed(width: 220) {
                ...GatsbyDatoCmsFixed
              }
            }
          }
        }
      }
    `}
    render={data => <Instructions {...data.datoCmsIntroduction} {...props} />}
  />
)
