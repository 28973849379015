import { StaticQuery } from 'gatsby';
import React from 'react';

import styles from '../sass/components/social.module.scss';

const Social = ({ edges }) => (
  <ul className={styles.social}>
    {edges.map(({ node }) => (
      <li key={node.id}>
        <a
          href={node.url}
          target="_blank"
          rel="noopener noreferrer"
          dangerouslySetInnerHTML={{
            __html: node.image.inlineSvg,
          }}
        />
      </li>
    ))}
  </ul>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allDatoCmsSocial {
          edges {
            node {
              url
              id
              image {
                inlineSvg
              }
            }
          }
        }
      }
    `}
    render={data => <Social {...data.allDatoCmsSocial} {...props} />}
  />
)
