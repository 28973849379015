import React from 'react';

import DontDrinkAndDrive from '../components/dontDrinkAndDrive';
import Header from '../components/header';
import Instructions from '../components/instructions';
import Intro from '../components/intro';
import Layout from '../components/layout';
import Lessons from '../components/lessons';
import animatePageStyles from '../sass/components/animate-page.module.scss';

export default () => (
  <Layout type="withbg">
    <Header />
    <div className={animatePageStyles.page}>
      <div className={animatePageStyles['page__in']}>
        <Intro />
        <Instructions />
        <Lessons />
        <DontDrinkAndDrive />
      </div>
    </div>
  </Layout>
)
