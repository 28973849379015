import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import styles from '../sass/components/intro.module.scss';
import Container from './container';
import Seo from './seo';

const Intro = ({ title, text, background }) => (
  <section className={styles.intro}>
    <Seo title={title} description={text} />
    <Container>
      <div className={styles.intro__content}>
        <h1 className={`${styles.intro__title}`}>{title}</h1>
        <div
          className={`h5 ${styles.intro__text}`}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </div>
    </Container>
  </section>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsIntroduction {
          title
          text
          background {
            url
            fluid(maxWidth: 5760) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    `}
    render={data => <Intro {...data.datoCmsIntroduction} {...props} />}
  />
)
