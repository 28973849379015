import React from 'react';

import styles from '../sass/components/header.module.scss';
import CloseBtn from '../svg/close';
import Logo from '../svg/logo';
import UserIcon from '../svg/userIcon';
import { getUserData } from '../utils/userData';
import Container from './container';
import Link from './link';

export default ({ showCloseBtnWithUrl }) => (
  <header className={styles.header}>
    <Container>
      <Link to="/" className={styles.header__logo}>
        <Logo />
      </Link>

      {showCloseBtnWithUrl ? (
        <Link to={showCloseBtnWithUrl} className={styles.header__close}>
          <span>Close</span>
          <CloseBtn />
        </Link>
      ) : (
        getUserData() && (
          <div className={styles.header__loggeduser}>
            <p>{getUserData().name}</p>
            <UserIcon />

            <Link to="/log-out">
              Log off
              <UserIcon />
            </Link>
          </div>
        )
      )}
    </Container>
  </header>
)
