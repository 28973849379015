import React from 'react'

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-440 142 18 18">
    <g>
      <path
        fill="#641714"
        d="M-426.1,154.4l-2.6-1.4c-0.5-0.3-0.8-0.6-0.8-0.9c0-0.7,1.4-1.6,1.4-4c0-2-1-3.3-2.8-3.3
		c-2,0-2.9,1.7-2.9,3.3c0,2.2,1.3,3.2,1.3,3.9c0,0.3-0.2,0.6-0.7,0.9l-2.6,1.4c-0.3,0.1-0.3,0.5-0.1,0.7c1.2,1.4,3,2.4,5,2.4
		c2,0,3.8-0.9,5-2.4C-425.8,154.9-425.8,154.6-426.1,154.4z"
      />
      <path
        fill="#C32032"
        d="M-431,142c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S-426,142-431,142z M-426,155.2c-1.2,1.4-3,2.4-5,2.4
		c-2,0-3.8-0.9-5-2.4c-0.2-0.2-0.1-0.6,0.1-0.7l2.6-1.4c0.6-0.3,0.7-0.6,0.7-0.9c0-0.7-1.3-1.7-1.3-3.9c0-1.7,0.9-3.3,2.9-3.3
		c1.8,0,2.8,1.3,2.8,3.3c0,2.4-1.4,3.3-1.4,4c0,0.3,0.2,0.6,0.8,0.9l2.6,1.4C-425.8,154.6-425.8,154.9-426,155.2z"
      />
    </g>
  </svg>
)
